<template>
  <div class="success">
      <h2>Message Received</h2>
      <p>Thanks for getting in touch! Click the close button to return home.</p>
  </div>
</template>

<style>
.success {
    text-align: center;
}
.success p {
    padding-top: 10px;
}
</style>